
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useGridItemManager } from '@/metrics/use-dashboards'

// Components
import DialogCard from '@/components/DialogCard.vue'

// Misc
import { GridItem, GridItemType } from '@/metrics/types'

export default defineComponent({
  name: 'GridItemCard',
  components: { DialogCard },

  props: {
    gridItem: {
      type: Object as PropType<GridItem>,
      required: true,
    },
    height: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    toolbarColor: {
      type: String,
      default: 'bg--none-primary',
    },
  },

  setup(props, ctx) {
    const menu = shallowRef(false)
    const dialog = shallowRef(false)

    const columnError = shallowRef(false)
    const routeForNewMonitor = computed(() => {
      switch (props.gridItem.type) {
        case GridItemType.Chart:
        case GridItemType.Table:
          return {
            name: 'MonitorMetricNew',
            query: {
              name: props.gridItem.title,
              metric: props.gridItem.params.metrics.map((m) => m.name),
              alias: props.gridItem.params.metrics.map((m) => m.alias),
              query: props.gridItem.params.query,
              columns: JSON.stringify(props.gridItem.params.columnMap),
            },
          }
        default:
          return undefined
      }
    })

    const gridItemMan = useGridItemManager()

    function del() {
      gridItemMan.delete(props.gridItem).then(() => {
        ctx.emit('change')
      })
    }

    return {
      GridItemType,

      menu,
      dialog,

      columnError,
      routeForNewMonitor,

      gridItemMan,
      del,
    }
  },
})
